import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import Text from '../../../components/ui/text'
import Button from '../../../components/ui/button'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import AccordionWrap from '../../../components/ui/accordion'
import { SectionWrap, LeftBox } from './about-area.style'


const AboutArea = (props) => {
    const aboutDataQuery = useStaticQuery(graphql`
        query infotechnoAboutQueryData {
            indexInfotechnoJson(id: {eq: "infotechno-about-content"}) {
                title
                subtitle
                desc
                path
                features {
                  id
                  text
                  title
                  path
                }
            }
        }
    `);
    const { title, subtitle, path, desc, features } = aboutDataQuery.indexInfotechnoJson;
    const { sectionTitleStyle, descStyle, btnStyle } = props
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={5}>
                        <LeftBox>
                            <SectionTitle
                                {...sectionTitleStyle}
                                title={title}
                                subtitle={subtitle}
                            />
                            {desc && <Text {...descStyle}>{desc}</Text>}
                            {path && <Button to={path} {...btnStyle}>Join Us Today</Button>}
                        </LeftBox>
                    </Col>
                    <Col lg={5} ml="auto">
                        <AccordionWrap layout="two">
                            <Accordion allowZeroExpanded preExpanded={['0']}>
                              {
                                features.map((el, index) => {
                                  return (
                                    <AccordionItem id={el.id} key={index} >
                                      <AccordionItemHeading>
                                        <AccordionItemButton>
                                          {el.title}
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <p>{el.text}</p>
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                  )
                                })
                              }
                            </Accordion>
                        </AccordionWrap>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

AboutArea.propTypes = {
    descStyle: PropTypes.object,
    btnStyle: PropTypes.object,
    sectionTitleStyle: PropTypes.object
}

AboutArea.defaultProps = {
    sectionTitleStyle: {
        mb: '20px',
        align: 'left'
    },
    descStyle: {
        mb: '30px'
    },
    btnStyle: {
        hover: "2"
    }
}

export default AboutArea;