import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import Heading from '../../../components/ui/heading'
import Anchor from '../../../components/ui/anchor'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import FeatureBox from '../../../components/box-image/layout-one'
import { SectionWrap } from './features-area.style'

const FeaturesArea = (props) => {
    const featuredDataQuery = useStaticQuery(graphql`
        query infotechnoFeatureQueryData {
            indexInfotechnoJson(id: {eq: "infotechno-featured-content"}) {
                title
                subtitle
            }
            allCatagoriesJson {
                edges {
                  node {
                    id
                    title
                    excerpt
                    box_image {
                      childImageSharp {
                        fluid(maxWidth: 370, maxHeight: 300, quality: 100, webpQuality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationWidth
                            presentationHeight
                            aspectRatio
                        }
                      }
                    }
                  }
                }
            }
            itDesignImage: file(relativePath: { eq: "images/box-image/it-design-box.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 370, maxHeight: 300, quality: 100, webpQuality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                        presentationHeight
                        aspectRatio
                    }
                }
            }
            businessComunicationImage: file(relativePath: { eq: "images/box-image/business-comunication-box.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 370, maxHeight: 300, quality: 100, webpQuality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                        presentationHeight
                        aspectRatio
                    }
                }
            }
            digitalMarketingImage: file(relativePath: { eq: "images/box-image/digital-marketing-box.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 370, maxHeight: 300, quality: 100, webpQuality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                        presentationHeight
                        aspectRatio
                    }
                }
            }
        }
    `);
    const featureSecData = featuredDataQuery.indexInfotechnoJson;
    const itDesignImage = featuredDataQuery.itDesignImage.childImageSharp;
    const businessComunicationImage = featuredDataQuery.businessComunicationImage.childImageSharp;
    const digitalMarketingImage = featuredDataQuery.digitalMarketingImage.childImageSharp;
    const { featureBoxStyles, linkStyle, headingStyle } = props;


    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            subtitle={featureSecData.subtitle}
                            title={featureSecData.title}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} md={6}>
                        <FeatureBox
                            {...featureBoxStyles}
                            title="IT Design"
                            imageSrc={itDesignImage}
                            featuresLeft={[
                                {id: "Business IT Support", text: "Business IT Support"},
                                {id: "Computer Security", text: "Computer Security"},
                            ]}
                            featuresRight={[
                                {id: "Backup & Recovery", text: "Backup & Recovery"},
                                {id: "Cloud Server", text: "Cloud Server"},
                            ]}
                            path="/it-design"
                        />
                    </Col>
                    <Col lg={4} md={6}>
                        <FeatureBox
                            {...featureBoxStyles}
                            title="Business Productivity"
                            imageSrc={businessComunicationImage}
                            featuresLeft={[
                                {id: "Microsoft 365", text: "Microsoft 365"},
                                {id: "Mircosoft Teams", text: "Mircosoft Teams"},
                            ]}
                            featuresRight={[
                                {id: "Cloud Phone System", text: "Business Voice"},
                                {id: "Remote Working", text: "Remote Working"}
                            ]}
                            path="/business-productivity"
                        />
                    </Col>
                    <Col lg={4} md={6}>
                        <FeatureBox
                            {...featureBoxStyles}
                            title="Digital Marketing"
                            imageSrc={digitalMarketingImage}
                            featuresLeft={[
                                {id: "Website Design", text: "Website Design"},
                                {id: "Web devlopment", text: "Web Development"},
                            ]}
                            featuresRight={[
                                {id: "SEO Optimisation", text: "SEO Optimisation"},
                                {id: "Web Hosting & Domains", text: "Web Hosting"},
                            ]}
                            path="/digital-marketing"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Heading {...headingStyle}>Book a free business health check today. <Anchor {...linkStyle} path="/contact-us">Book yours now!</Anchor></Heading>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

FeaturesArea.propTypes = {
    featureBoxStyles: PropTypes.object,
    headingStyle: PropTypes.object,
    linkStyle: PropTypes.object
}

FeaturesArea.defaultProps = {
    featureBoxStyles: {
        mt: '60px',
        responsive: {
            small: {
                mt: '47px'
            }
        }
    },
    headingStyle: {
        as: 'h3',
        fontSize: '18px',
        fontweight: 500,
        lineHeight: 1.40,
        color: '#333333',
        mt: '60px',
        textalign: 'center',
        responsive: {
            small: {
                mt: '45px'
            }
        }
    },
    linkStyle: {
        layout: 'underline',
        hover: {
            layout: 2
        }
    }
}

export default FeaturesArea;